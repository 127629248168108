<template>
  <div class="retailer-setup-page">
    <h1 class="title">
      Move your online store to the next level & make an impact.
    </h1>
    <span class="sub-title"
      >Below is a short checklist you need to finish to start with Vgang.</span
    >
    <div class="steps">
      <div class="each-step step-one">
        <div class="badge">
          <img src="@/assets/img/step-star.png" alt="star" />
          <span class="text">Step 1</span>
        </div>
        <div>
          <h2 class="step-title">Connect your Shopify store</h2>
          <span class="step-subtitle"
            >Your Store Name and URL may be different. Be sure to refer to the
            URL, and not the Store Name.</span
          >
          <ul>
            <li>
              Please provide the name of your store as it appears in the URL:
              store-name.myshopify.com
            </li>
          </ul>
          <div class="shopify-url-input-wrapper form">
            <CustomInput
              type="text"
              id="shopify-url"
              label="Shopify url"
              v-model="shopifyURL"
              fixedValue=".myshopify.com"
              :disabled="shopInstalled"
            />
            <button
              v-if="!shopInstalled"
              class="vgang-button button"
              @click="connectShop()"
              :disabled="IntegrationButtonIsDisabled"
              :class="IntegrationButtonIsDisabled ? 'disabled' : ''"
            >
              Save & Install
            </button>
            <button v-else class="vgang-button button success-deactive-button">
              Installed Successfully
            </button>
          </div>
          <div
            class="errorMessage-container"
            style="margin-top: 0"
            v-if="showAssignShopError"
          >
            <div class="material-icons error-icon">cancel</div>
            <div>
              Ops! something happened
              <span>{{ assignShopError }}</span>
            </div>
          </div>
        </div>
        <div class="step-counter">
          <div class="circles">
            <div class="circle-full"></div>
            <div class="circle-empty"></div>
            <div class="circle-empty"></div>
          </div>
          <span class="text">1/3 Completed</span>
        </div>
      </div>
      <div class="each-step step-two">
        <div class="badge">
          <img src="@/assets/img/step-star.png" alt="star" />
          <span class="text">Step 2</span>
        </div>
        <div class="step-middle-content">
          <div>
            <h2 class="step-title">
              Add your first product to your online store
            </h2>
            <span class="step-subtitle"
              >Find products that you want to sell in your store.</span
            >
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=32L2GtgXtIw"
              class="watch-vid"
            >
              <img src="@/assets/img/play.png" alt="play" />
              Watch a video: How to find a product and import it to my online
              store?
            </a>
          </div>
          <buttonLink
            :isInternal="true"
            :content="' Explore List of Products'"
            :destination="'/retailer/list-of-products'"
            :filled="true"
          >
          </buttonLink>
        </div>
        <div class="step-counter">
          <div class="circles">
            <div class="circle-full"></div>
            <div class="circle-full"></div>
            <div class="circle-empty"></div>
          </div>
          <span class="text">2/3 Completed</span>
        </div>
      </div>
      <div class="each-step step-three">
        <div class="badge">
          <img src="@/assets/img/step-star.png" alt="star" />
          <span class="text">Step 3</span>
        </div>
        <div>
          <h2 class="step-title">Join Our Community</h2>
          <span class="step-subtitle"
            >Connect with Fellow Dropshipper and Suppliers on LinkedIn to Share
            Experiences, Gain Insights, and Grow Together.</span
          >
          <div class="shopify-url-input-wrapper form">
            <a
              target="_blank"
              href="https://www.linkedin.com/search/results/all/?keywords=dropshipping%20for%20impact%20entrepreneurs&origin=TYPEAHEAD_HISTORY&searchId=3acddb88-06d2-47ad-9c15-94c181289e6a&sid=nQ%40"
            >
              <button
                class="vgang-button button"
                v-clipboard:copy="referalCode"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                Join LinkedIn Community
              </button>
            </a>
          </div>
        </div>
        <div class="step-counter">
          <div class="circles">
            <div class="circle-full"></div>
            <div class="circle-full"></div>
            <div class="circle-last"></div>
          </div>
          <span class="text">3/3 Completed</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import SettingService from "@/services/SettingService";
import RegisterationSetup from "@/services/RegisterationSetup";
import ValidateService from "@/services/ValidateService";
import buttonLink from "@/components/bases/ButtonLink.vue";

import {
  setVariable,
  getVariable,
  removeVariable,
} from "@/utils/conditionalStoreVariables";
export default {
  components: {
    CustomInput,
    buttonLink,
  },
  data() {
    return {
      shopifyURL: "",
      shopInstalled: false,
      showAssignShopError: false,
      assignShopError: "",
      referalCode: window.location.href + "?" + getVariable("email"),
    };
  },
  mounted() {
    if (this.$route.query?.currency_iso) {
      this.currency_iso = this.$route.query.currency_iso;
    } else if (getVariable("currency_iso")) {
      this.currency_iso = getVariable("currency_iso");
    } else {
      this.currency_iso = null;
    }

    if (this.$route.query.shop_id) {
      this.shop_id = this.$route.query.shop_id;
    } else if (getVariable("shop_id")) {
      this.shop_id = getVariable("shop_id");
    } else {
      this.shop_id = null;
    }

    if (this.$route.query.platform_name) {
      this.platform_name = this.$route.query.platform_name;
    } else if (getVariable("platform_name")) {
      this.platform_name = getVariable("platform_name");
    } else {
      this.platform_name = null;
    }

    if (this.$route.query.timestamp) {
      this.timestamp = this.$route.query.timestamp;
    } else if (getVariable("timestamp")) {
      this.timestamp = getVariable("timestamp");
    } else {
      this.timestamp = null;
    }

    if (this.$route.query.state) {
      this.state = this.$route.query.state;
    } else if (getVariable("state")) {
      this.state = getVariable("state");
    } else {
      this.state = null;
    }

    if (this.$route.query.hmac) {
      this.hmac = this.$route.query.hmac;
    } else if (getVariable("hmac")) {
      this.hmac = getVariable("hmac");
    } else {
      this.hmac = null;
    }
    if (this.shop_id && this.platform_name) {
      setVariable("currency_iso", this.currency_iso);
      setVariable("shop_id", this.shop_id);
      setVariable("platform_name", this.platform_name);
      setVariable("timestamp", this.timestamp);
      setVariable("state", this.state);
      setVariable("hmac", this.hmac);

      this.retailerValidate();
    } else {
      this.getAccountInfo();
    }
    // shop_id=hesamtest.myshopify.com&currency_iso=EUR&platform_name=shopifyfeeder&timestamp=1634230575&state=57372&hmac=uFxRDncyyXe0HWJJuBXZQMxe0jqCOIejc88aU1nzGyY%3D
  },
  computed: {
    IntegrationButtonIsDisabled() {
      if (this.shopifyURL && this.shopifyURL.length > 0) return false;
      else return true;
    },
  },
  methods: {
    getAccountInfo() {
      SettingService.getAccountInfo()
        .then((response) => {
          this.shopifyURL = response.data.shopUrl;

          if (response.data.shopUrl && response.data.shopInstalled) {
            this.shopInstalled = true;
          } else {
            this.shopInstalled = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    connectShop() {
      const params = {
        storeUrl: this.shopifyURL,
        platformName: "shopifyfeeder",
      };
      RegisterationSetup.installLinkRetailer(params)
        .then((response) => {
          this.redirectURL = response.data.redirectUrl;
          window.location.replace(this.redirectURL);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retailerValidate() {
      const currencyIso = getVariable("currency_iso");
      const shopId = getVariable("shop_id");
      const platformName = getVariable("platform_name");
      const timestamp = getVariable("timestamp");
      const state = getVariable("state");
      const hmac = getVariable("hmac");
      const params = {
        currencyIso: currencyIso ? currencyIso : "",
        storeUrl: shopId ? shopId : "",
        platformName: platformName ? platformName : "",
        timestamp: timestamp ? timestamp : "",
        state: state ? state : "",
        hmac: hmac ? hmac : "",
      };
      ValidateService.retailerValidate(params)
        .then((response) => {
          this.showAssignShopError = false;
          this.getAccountInfo();
          removeVariable("currency_iso");
          removeVariable("shop_id");
          removeVariable("platform_name");
          removeVariable("timestamp");
          removeVariable("state");
          removeVariable("hmac");
          if (response.status == 200) {
            this.SuccessDialog = true;
            this.dialog = false;
            this.validateDialogeMessage = "Shop installed successfully!";
          }
        })
        .catch((e) => {
          this.assignShopError = e.response.data.message;
          this.showAssignShopError = true;
          removeVariable("currency_iso");
          removeVariable("shop_id");
          removeVariable("platform_name");
          removeVariable("timestamp");
          removeVariable("state");
          removeVariable("hmac");
          this.getAccountInfo();
        });
    },

    onCopy: function (e) {
      console.log(e);
    },
    onError: function (e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss">
.retailer-setup-page {
  padding: 24px;
  .title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000f29;
  }
  .sub-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #435160;
    margin-bottom: 24px;
    display: block;
  }
  .steps {
    .badge {
      border-radius: 5px;
      background-color: #ff7e9d;
      display: flex;
      width: 85px;
      align-items: center;
      justify-content: space-around;
      margin-right: 24px;
      img {
        width: 9px;
      }
      .text {
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #fff;
      }
    }
    .step-counter {
      position: absolute;
      right: 8px;
      top: 24px;
      //display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      .text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #95a5b6;
      }
      .circles {
        display: flex;
        width: 40px;
        align-items: center;
        justify-content: space-between;
        margin-right: 24px;
        .circle-full {
          width: 9px;
          height: 9px;
          background: #008f4a;
          border-radius: 9px;
        }
        .circle-empty {
          width: 9px;
          height: 9px;
          background: #cfd5de;
          border-radius: 9px;
        }
        .circle-last {
          width: 9px;
          height: 9px;
          background: #00e676;
          border-radius: 9px;
        }
      }
    }
    .each-step {
      position: relative;
      //display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 24px 24px 24px 12px;
      border-radius: 10px;
      box-shadow: 0 0 50px 10px rgba(29, 41, 54, 0.1);
      background-color: #fff;
      margin-bottom: 12px;
      .step-title {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1d2936;
        margin-top: 24px;
      }
      .step-subtitle {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #435160;
        margin-bottom: 12px;
        display: block;
      }
      ul {
        li {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #111418;
        }
      }
      .shopify-url-input-wrapper {
        //display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        .button {
          width: unset !important;
          font-size: 14px;
          font-weight: 600;
        }
      }
      &.step-two {
        .step-subtitle {
          margin-bottom: 40px;
        }
        .step-middle-content {
          //display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: flex-end;
        }
        .watch-vid {
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #435160;
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          img {
            width: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
