<template>
  <div v-if="userType == 'retailer'">
    <retailerNavigation />
    <v-main app>
      <div>
        <div v-if="(currentstep = 1)">
          <firstStep :selected="true"> </firstStep>
        </div>
        <div></div>
      </div>
    </v-main>
  </div>
</template>

<script>
import retailerNavigation from "@/components/navigation/retailer.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
import firstStep from "@/components/retailer/letsStart/firstStep.vue";
import secondStep from "@/components/retailer/letsStart/secondStep.vue";

import HomePage from "@/services/HomePage";
export default {
  data() {
    return {
      userType: "retailer",
      checkboxes: [],
      totalstep: 4,
      step: 1,
      categories: [],
    };
  },

  components: {
    buttonLink,
    retailerNavigation,
    firstStep,
    secondStep,

    HomePage,
  },
  computed: {},

  mounted() {
    this.getPageData();
  },
  methods: {
    getPageData() {
      let data = HomePage.getHomePageData();
      this.categories = data.categories;
     
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables2.scss";
.page {
  background: #ffffff;
  min-height: 93vh;
}
.query_container {
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  background: linear-gradient(90deg, #eefbfc 0%, #fefefe 100%);
}
.flex-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 48px;
}
.flex-container > div {
  background-color: #ffffff;

  align-items: center;
  display: flex;
  padding: 16px;
  width: 100%;
  max-width: 442px;
}
.firstlist {
  display: grid;
  flex-wrap: wrap;
}
.secondlist {
  display: grid;
  flex-wrap: wrap;
  margin-top: 72px;
}
.listText {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 120px 270px;
  width: 100%;
}
.boxText {
  margin-top: 144px;
}
.bigText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}
.recommendations {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}
.textBottem {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 32px;
}
.greenCategory {
  color: $green-600;
}

.checkboxText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin-left: 8px;
}
.startbutton {
  margin-bottom: 32px;
}
.buttom {
  margin-top: 48px;
  justify-content: space-between;
  display: flex;
}
.ques {
  margin-top: 48px;
}
</style>
