<template>
  <main>
    <div v-if="userType == 'retailer'">
      <retailerNavigation />
      <v-main app>
        <div class="page-wrapper-retailer">
          <retailerSetup />
        </div>
      </v-main>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-12">
          <div class="no-access">You don't have access to this panel</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import retailerSetup from "@/components/retailerSetup.vue";
import retailerNavigation from "@/components/navigation/retailer.vue";
import setup from "@/components/retailer/letsStart/setup.vue";
// Services
import CommonData from "@/services/CommonData";
import { setVariable } from "@/utils/conditionalStoreVariables";
export default {
  components: { retailerNavigation, retailerSetup, setup },
  data() {
    return {
      userType: "retailer",
    };
  },

  mounted() {
    CommonData.getCurrentUser()
      .then(function (response) {
        this.userType = response.data.role;
        setVariable("role", this.userType);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style lang="scss">
.no-access {
  border-radius: 10px;
  text-align: center;
  color: #ff0a43;
  padding: 30px;
  border: 1px solid #ff0a43;
}
</style>
